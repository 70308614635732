export default function () {
    const regularActivityGroups = [
        {
            title: '貴き親理のお授けを頂く',
            article:
                '『大親神大生命』の直接のお授けを頂いております。\n \
                『大親神大生命』がして下さっておられる大自然の大いなる親理（真理法則）を学修させて頂いております。\n \
                『大親神大生命』は地球人の意識が高まり、大自然の真理法則・摂理に沿った生き方になるように、そして地球がお互い助け合うプラスの生き方の惑星になるように、お授け下さっています。',
            activities: [
                {
                    title: '理修会',
                    imgUrl: require('@/assets/activity/regular-1-1.jpg'),
                    article:
                        '『大親神大生命』の直接の貴き親理のお授けを頂き、『大親神大生命』がして下さっておられる大自然の大いなる親理（真理法則・摂理）を学修させて頂いております。\n \
                        『大親神大生命』は、私たち地球人の意識が高まり大自然の真理法則に沿った素晴らしい生き方が出来るように、そして、地球がお互いに助け合うプラスの生き方の惑星になるように、お授け下さっておられます。',
                    routeUrl: { name: 'Doctrine' },
                    routeSpan: '貴き親理のお授けけ',
                },
                {
                    title: '真理学修会・勉強会',
                    imgUrl: require('@/assets/activity/regular-1-2.jpg'),
                    article:
                        '「真理学修会」は、本部（大親所）をはじめ、関東・九州など各地域の支所、あるいは勉強グループ（個人宅）などで定期的に開催しております。『大親神大生命』の貴き親理のお授け（DVD）を頂き、その後に内容を反芻したり感想を話し合って理解を深めます。\n \
                        \n \
                        「真理学修会」の目的は、大自然の生命の大親『大親神大生命』の御存在と生命愛を実感し、大自然の真理法則に沿って、より良く素晴らしい人生を生きていけるようになることです。『大親神大生命』の貴き親理のお授けを頂くと、毎回「目から鱗」で、ものの見え方や感じ方が全く変わります。日々の喜びと感謝がますます増して、実生活がより楽しい充実したものになります。\n \
                        \n \
                        大自然の真理法則を学ばせて頂く事は、老若男女に関係無く、全ての人々にとって大切なことです。ぜひ一度ご参加下さい。',
                },
            ],
        },
        {
            title: '親感謝を捧げさせて頂く',
            article:
                '一年間の様々な行事を通して、『大親神大生命』に対し奉り、『大親神大生命』が行って下さっている貴き営みに対しての親感謝 <sup class="smaller"> ※ </sup> を行わせて頂きます。\n \
                （※『大親神大生命』に対し奉りの感謝を「親感謝」と言います。）',
            activities: [
                {
                    title: '親祭典',
                    imgUrl: require('@/assets/activity/regular-2-1.jpg'),
                    article:
                        '「親祭典」は、生命の御子である私たちが、生命の大親に対して、生存の喜びと感謝の御挨拶を行わせて頂く行事です。全ての生命の御子を代表して、『大親神大生命』に対し奉り、この地球上の全生物が活かされ生きている事の親感謝を捧げさせて頂きます。\n \
                        \n \
                        「親祭典」は、一宗教・一国家・一民族の宗教的・国家的・民族的行事ではなく、生命ある全生物の感謝の祭典です。宗教宗派や人種民族・老若男女を越えて、誰もが参加できる人類普遍共通の行事です。\n \
                        『大親神大生命』に対し奉り親感謝を行なわせて頂くと、『大親神大生命』はその真心をお受け取り下さり、素晴らしい現象を地球にも個人にもお与え下さいます。',
                    routeUrl: { name: 'Parents' },
                    routeSpan: '詳しく見る',
                },
                {
                    title: '真和会',
                    imgUrl: require('@/assets/activity/regular-2-2.jpg'),
                    article:
                        '親祭典や理修会の後に、会食やレクリエーションがあります。\n \
                        『大親神大生命』にお受け取り頂けるように、感謝や喜びを語り合います。',
                },
            ],
        },
    ]
    const routineActivities = [
        {
            title: '真参拝の理',
            imgUrl: require('@/assets/activity/routine-1.jpg'),
            article:
                '「生命の大親」である『大親神大生命』に対し奉り行わせて頂く親感謝の御挨拶です。\n \
                一日の始め・一日の終わりに、あるいは、仕事を開始・完了した時、どこかに出かける前・帰宅した後などに、『大親神大生命』に対し奉り誠と真心を込めて「『大親神大生命』、よろしくお願い申し上げます」「『大親神大生命』、ありがとうございました！」と御挨拶を行わせて頂きます。\n \
                \n \
                『大親神大生命』に対し奉り感謝の真参拝を毎日行なわせて頂くと、『大親神大生命』の大きな生命の愛をより一層実感させて頂けるようになります。『大親神大生命』に活かされ生きる感謝と感動、プラスの気持ちが湧いてきて、誰でも素晴らしい人生になります。生きる力、気持ちが全く違ってきます。素晴らしい事ですので、ぜひ実行して違いを実感してみて下さい。',
        },
        {
            title: 'おつとめ親交式の理',
            imgUrl: require('@/assets/activity/routine-2.jpg'),
            article:
                '「おつとめ親交式」は、「生命の大親」である『大親神大生命』に対し奉り、私たち「生命の御子」が行わせて頂く御挨拶であり、礼儀です。『大親神大生命』に対し奉り、今日も一日、生命を頂いて活かされ生きている事の親感謝を、全生物を代表して行わせて頂きます。\n \
                \n \
                『大親神大生命』は大いなる生命のお力・お働きと、大いなる生命の愛をもって、あらゆる全ての営みを行っていて下さいます。私たちの目が見える事、耳が聞こえる事、心臓の鼓動、呼吸、消化、新陳代謝、あるいは地球の自然環境、水・空気の循環、全生物の生存、惑星の自転・公転、宇宙の壮大で膨大な仕組み…。\n \
                 \n \
                これほどの事をして頂いていながら、大変申し訳ないことに、私たち人類は『大親神大生命』の御存在・御心が分からず、当然のこと、当たり前のこととして、一度も感謝をさせて頂いた事がありませんでした。\n \
                \n \
                『大親神大生命』に対し奉り、全生物の生存の感謝と喜びの御挨拶を行わせて頂く、最も大切な礼儀が「おつとめ親交式」です。『大親神大生命』に対し奉り、親感謝の奏上詞（のりと）を奏上させて頂き、地球が素晴らしい惑星になるように念じさせて頂きます。「おつとめ親交式」を行わせて頂く事は、生命の御子として最も大切な役割を果たす事であり、素晴らしい徳積みとなります。',
        },
        {
            title: 'おつなぎ徳積みの理',
            imgUrl: require('@/assets/activity/routine-3.jpg'),
            article:
                '「おつなぎ徳積みの理」は、『大親神大生命』にお受取り頂けるよう、人のため世のために行うプラスの種まきです。\n \
                『大親神大生命』に対し奉り「地球が素晴らしい惑星となりますように」「あの人がプラスになりますように」と、他のプラスを願ってプラスの行いをさせて頂く事が「おつなぎ徳積みの理」です。\n \
                \n \
                『大親神大生命』に「おつなぎ徳積みの理」としてお受け取り頂けると、他の為に役立つ行いをした＝人助けのプラスの種を蒔いた事になります。人助けのプラスの種が、「循環・還元の理法」により、自分にプラスの収穫として還ってきます。『大親神大生命』は相手をプラスにする者に、プラスの現象をお与え下さるのです。',
            routeUrl: { name: 'Donate' },
            routeSpan: '詳しく見る',
        },
        {
            title: '親理 総合理学修の理',
            imgUrl: require('@/assets/activity/routine-4.jpg'),
            article:
                '「親理　総合理学修の理」とは、『大親神大生命』の貴き親理のお授けを頂いた後に、全員で行う話し合いです。お授け頂いた内容を反芻させて頂いて、理解と実感を深めてゆきます。\n \
                『大親神大生命』より貴き親理のお授けを頂いた中で、自分の心に沁みた事、強く印象に残った事、はっと気づかせて頂いた事など、お互いの実感を語り合い、親感謝・真感動を高め合います。',
        },
        {
            title: '純御修行の理',
            imgUrl: require('@/assets/activity/routine-5.jpg'),
            article:
                '何事も「『大親神大生命』、この世（親物質現象界）の素晴らしい味わいをさせて頂き、ありがとうございます！」と親感謝させて頂く事、これが大自然界の「純御修行の理」です。\n \
                \n \
                『大親神大生命』は私たち生命の御子に、素晴らしさ・喜び・感動をお与え下さる為にこの世をお創り下さり、それぞれに体を与え、生かして下さっています。\n \
                ですから何事も、『大親神大生命』に対し奉り感謝して、喜び楽しんで生きることが、私たち「生命の御子」としての本来の生き方なのです。\n \
                 \n \
                『大親神大生命』の生命愛を感じさせて頂きますと、物事の感じ方、考え方がどんどん変わっていきます。活かされ生きる生存の喜び・感謝・感動の人生に変わります。ぜひ一緒に体感しましょう！',
            routeUrl: { name: 'Training' },
            routeSpan: '詳しく見る',
        },
        {
            title: '道路清掃ボランティア「クリーン＆グリーン」',
            imgUrl: require('@/assets/activity/routine-6.jpg'),
            article:
                '『大親神大生命』に地球のプラスの生き方の種としてお受取り頂けるようにとの思いから、2020年1月より、大自然界の半径3km以内の県道の道路掃除を行わせていただいております。全員、手作りの黄色いジャケットを着用し、安全を確保しながら、楽しく清掃作業を行わせていただいております\n \
                \n \
                発足翌月の2020年2月には、自主的なボランテイア活動として\n \
                ・勝浦地区「道路掃除ボランティア」\n \
                ・いすみ地区「クリーン＆グリーン」として\n \
                それぞれの市役所に受理されました。\n \
                \n \
                現場に向かう前には、必ず全員で「真参拝の理」を行わせていただき、「地球の良い行いの種としてお受取りいただけますよう、頑張らせていただきます！」と決意申し上げてから出発します。\n \
                作業から帰着後にも「真参拝の理」を行わせていただき、「『大親神大生命』のお力お働きをいただいて無事に務めさせていただく事が出来ました。ありがとうございました！」と御礼を申し上げます。\n \
                \n \
                県道沿いには大量のゴミが不法投棄されており、その量は毎回35～85kgにも達します。\n \
                それぞれ担当箇所に分かれて「不法投棄が無くなりますように」「地球上からマイナスの気持ちが無くなりますように」という気持ちと念を込めながら、一つずつ片付けていきます。\n \
                ありがたいことに、いすみ市役所の担当者様からは「勝浦市民であるのに、いすみ市の道路のゴミ拾いをしていただき感謝です」との言葉をいただきました。\n \
                \n \
                『大親神大生命』にお受取りいただく事ができますよう、今後とも地域振興と活性化のため、そして地球のために活動を続けさせていただきます。',
        },
    ]
    return { regularActivityGroups, routineActivities }
}

<template>
    <div id="Activities">
        <section id="activities-banner" class="banner-section-style">
            <div class="mobile-title hide">
                <h1 class="f20-mb">大自然界の活動</h1>
                <p class="f12-mb">大自然界の様々な活動をご紹介します。</p>
            </div>
            <div
                class="banner"
                :style="
                    addBackgroundImage(
                        require('@/assets/background/background-4.jpg'),
                        require('@/assets/background/background-4-mb.jpg')
                    )
                "
            ></div>
        </section>
        <section id="activities-title" class="title-section-style hide-mb">
            <h2 class="title f29">大自然界の活動</h2>
            <p class="subtitle f14">大自然界の様々な活動をご紹介します。</p>
        </section>
        <section id="activities-main">
            <div class="wrap w80">
                <aside class="sticky-container-style hide-mb f13">
                    <div class="menu-group">
                        <h3 class="head">定期行事</h3>
                        <ul
                            v-for="(item, index) in regularActivityGroups"
                            :key="'regular-menu-' + index"
                            class="body"
                        >
                            <li
                                class="group-title"
                                @click="
                                    scrollToElement(
                                        '#regular-activities-' + index
                                    )
                                "
                            >
                                {{ item.title }}
                            </li>
                            <li
                                v-for="(subitem, subindex) in item.activities"
                                :key="'regular-menu-' + index + '-' + subindex"
                                class="menu-item"
                                @click="
                                    scrollToElement(
                                        '#regular-activities-' +
                                            index +
                                            '-' +
                                            subindex
                                    )
                                "
                            >
                                ・{{ subitem.title }}
                            </li>
                        </ul>
                    </div>
                    <div class="menu-group">
                        <h3 class="head">日々の行い</h3>
                        <ul class="body">
                            <li
                                v-for="(item, index) in routineActivities"
                                :key="'routine-menu-' + index"
                                class="menu-item"
                                @click="
                                    scrollToElement(
                                        '#routine-activities-' + index
                                    )
                                "
                            >
                                ・{{ item.title }}
                            </li>
                        </ul>
                    </div>
                </aside>
                <main>
                    <div id="activities-regular-group" class="activities-group">
                        <h2 class="head f29 f20-mb">定期行事</h2>
                        <div
                            class="body"
                            v-for="(item, index) of regularActivityGroups"
                            :key="'regular-activities-' + index"
                            :id="'regular-activities-' + index"
                        >
                            <article class="activity-article-style">
                                <h3 class="title f29 f20-mb">
                                    {{ item.title }}
                                </h3>
                                <p
                                    class="article f14 f12-mb"
                                    v-html="wrap(item.article)"
                                ></p>
                            </article>
                            <article
                                v-for="(subitem, subindex) of item.activities"
                                class="activity-article-style"
                                :key="
                                    'regular-activities-' +
                                    index +
                                    '-' +
                                    subindex
                                "
                                :id="
                                    'regular-activities-' +
                                    index +
                                    '-' +
                                    subindex
                                "
                            >
                                <h3 class="title f29 f20-mb">
                                    {{ subitem.title }}
                                </h3>
                                <img v-lazy="subitem.imgUrl" />
                                <p
                                    class="article f14 f12-mb"
                                    v-html="wrap(subitem.article)"
                                ></p>
                                <div
                                    class="page-link-button"
                                    v-if="subitem.routeSpan"
                                >
                                    <router-link
                                        class="f14 f12-mb"
                                        :to="subitem.routeUrl"
                                    >
                                        {{ subitem.routeSpan }}
                                        <i class="icon-page-link f12"></i>
                                    </router-link>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div id="activities-routine-group" class="activities-group">
                        <h2 class="head f29 f20-mb">日々行っている事</h2>
                        <div class="body">
                            <article
                                v-for="(item, index) of routineActivities"
                                :key="'routine-activities-' + index"
                                :id="'routine-activities-' + index"
                                class="activity-article-style"
                            >
                                <h3 class="title f29 f20-mb">
                                    {{ item.title }}
                                </h3>
                                <img v-lazy="item.imgUrl" />
                                <p
                                    class="article f14 f12-mb"
                                    v-html="wrap(item.article)"
                                ></p>
                                <div
                                    class="page-link-button"
                                    v-if="item.routeSpan"
                                >
                                    <router-link
                                        class="f14 f12-mb"
                                        :to="item.routeUrl"
                                    >
                                        {{ item.routeSpan }}
                                        <i class="icon-page-link f12"></i>
                                    </router-link>
                                </div>
                            </article>
                        </div>
                    </div>
                </main>
            </div>
        </section>
    </div>
</template>
<script>
import { addBackgroundImage, wrap, scrollToElement } from '@/utils/common.js'
import getActivitiesData from '@/data/getActivitiesData.js'
export default {
    name: 'Activities',
    setup() {
        const { regularActivityGroups, routineActivities } = getActivitiesData()
        return {
            addBackgroundImage,
            wrap,
            scrollToElement,
            regularActivityGroups,
            routineActivities,
        }
    },
}
</script>
<style lang="scss" scoped>
#activities-main {
    .wrap {
        display: flex;
    }
    aside {
        flex: 0 0 220px;
        margin-right: 50px;

        .group-title {
            position: relative;
            cursor: pointer;
            transition: 0.3s;
            &::before {
                content: '';
                position: absolute;
                width: 6px;
                border: 1px solid $majorPurple;
                left: -15px;
                top: 0.8em;
            }
            &:hover {
                color: $gray3;
            }
        }
        .body {
            padding-left: 10px;
        }
    }
    main {
        flex: 1;
    }
    .head {
        color: white;
        background-color: $majorPurple;
        padding: 0 20px;
    }
    #activities-regular-group {
        article {
            border-bottom: none;
        }
    }

    @media screen and (min-width: 768px) {
        background-color: $minorGold;
        padding: 100px 0;
        #activities-regular-group {
            .body {
                margin: 30px 0;
                box-shadow: $shadow2;
                background-color: white;
            }
        }
        #activities-routine-group {
            margin-top: 100px;
        }
    }
    @media screen and(max-width:767px) {
        .head {
            margin-top: 50px;
            line-height: 2em;
        }
        #activities-regular-group {
            .body {
                border-bottom: 1px solid $grayB;
                &:last-child {
                    border-bottom: none;
                }
            }
        }
        #activities-routine-group {
            background-color: $minorGold;
        }
    }
}
</style>
